import {
  ArrowDownOnSquareIcon,
  ArrowDownOnSquareStackIcon,
} from "@heroicons/react/24/outline";
import React from "react";

import { ActiveUsageTable } from "../../components/ActiveUsageTable";
import { Divider } from "../../components/Divider";
import { DurationChart } from "../../components/DurationChart";
import { EngagementBarChart } from "../../components/EngagementBarChart";
import { GainChart } from "../../components/GainChart";
import { Grid } from "../../components/Grid";
import { ImprovementChart } from "../../components/ImprovementChart";
import { LocationChart } from "../../components/LocationChart";
import { Panel } from "../../components/Panel";
import { RatingChart } from "../../components/RatingChart";
import { WaveformChart } from "../../components/WaveformChart";
import { download_usage_report } from "../../lib/download-usage-report";

export const TabEngagement = ({
  phi,
  partnerId,
  groupIds,
  getAccessTokenSilently,
}: {
  phi: boolean;
  partnerId: string;
  groupIds: string[];
  getAccessTokenSilently: Function;
}) => {
  return (
    <>
      <Grid>
        <Panel
          title="Engaged by Month"
          subTitle="% users that have had one or more HeatWave sessions per month"
        >
          <EngagementBarChart
            filter={{ partnerId, groupIds, includeDirect: false }}
          />
        </Panel>
        <Panel
          title="Heat Sessions"
          subTitle="By pain location since program initiation"
        >
          <LocationChart
            filter={{ partnerId, groupIds, includeDirect: false }}
          />
        </Panel>
        <Panel title="Improvement">
          <ImprovementChart
            filter={{ partnerId, groupIds, includeDirect: false }}
          ></ImprovementChart>
        </Panel>
      </Grid>
      <Grid>
        <Panel title="Heat Profile">
          <WaveformChart
            filter={{ partnerId, groupIds, includeDirect: false }}
          />
        </Panel>
        <Panel title="Duration">
          <DurationChart
            filter={{ partnerId, groupIds, includeDirect: false }}
          />
        </Panel>
        <Panel title="Temperature">
          <GainChart filter={{ partnerId, groupIds, includeDirect: false }} />
        </Panel>
      </Grid>
      <Divider />
      <Grid>
        {phi && (
          <Panel
            title="Usage Last 30 Days"
            buttons={
              <>
                <a
                  href="#"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    download_usage_report(
                      e,
                      getAccessTokenSilently,
                      "30day",
                      partnerId,
                      groupIds,
                    )
                  }
                >
                  <ArrowDownOnSquareIcon strokeWidth={2} />
                </a>
                <a
                  href="#"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    download_usage_report(
                      e,
                      getAccessTokenSilently,
                      "full",
                      partnerId,
                      groupIds,
                    )
                  }
                >
                  <ArrowDownOnSquareStackIcon strokeWidth={2} />
                </a>
              </>
            }
          >
            <ActiveUsageTable filter={{ partnerId, groupIds }} />
          </Panel>
        )}
        {!phi && (
          <>
            <Panel title="Pain Rating Change">
              <RatingChart filter={{ partnerId, groupIds }} />
            </Panel>
            <Panel></Panel>
          </>
        )}
      </Grid>
    </>
  );
};
