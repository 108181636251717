import { useState } from "react";

export const useGroupForm = (addPartnerGroup: any, filter: any) => {
  const [isDefaultGroup, setIsDefaultGroup] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [groupIdError, setGroupIdError] = useState(false);
  const [groupNameError, setGroupNameError] = useState(false);

  const resetForm = () => {
    setIsDefaultGroup(false);
    setGroupId("");
    setGroupName("");
    setGroupIdError(false);
    setGroupNameError(false);
  };

  const handleSubmit = () => {
    if (groupId.length <= 0) {
      setGroupIdError(true);
      return false;
    }
    if (groupName.length <= 0) {
      setGroupNameError(true);
      return false;
    }

    addPartnerGroup({
      variables: {
        groupId: groupId,
        name: groupName,
        partnerId: filter?.partnerId,
        isDefault: isDefaultGroup,
      },
    });

    resetForm();
    return true;
  };

  return {
    isDefaultGroup,
    setIsDefaultGroup,
    groupId,
    setGroupId,
    groupName,
    setGroupName,
    groupIdError,
    groupNameError,
    handleSubmit,
    resetForm,
  };
};
