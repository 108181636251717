import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Select as FormSelect } from "../../components/Forms/Select";
import { PartnerGroup } from "../../types/patner-group.types";

export const selectGroups = (
  sortedPartnerGroups: PartnerGroup[],
  selectedGroups: string[],
  onChange: any,
  multiSelect: boolean = false,
) => {
  const animatedComponents = makeAnimated();

  const defaultValue: any = [];
  const options: any =
    sortedPartnerGroups?.map(({ groupId, name, isDefault }) => {
      const optionName = `${name}${isDefault ? " (Default)" : ""}`;
      const option = { value: groupId, label: optionName };

      if (selectedGroups?.includes(groupId)) {
        defaultValue.push(option);
      } else if (isDefault && !selectedGroups?.length) {
        defaultValue.push(option);
      }

      return option;
    }) ?? [];

  if (multiSelect) {
    return (
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        defaultValue={defaultValue}
        isMulti
        options={options}
        onChange={onChange}
      />
    );
  }

  const default_value: string =
    selectedGroups && selectedGroups.length > 0 ? selectedGroups[0] : "";

  return (
    <>
      {sortedPartnerGroups && sortedPartnerGroups.length > 0 && (
        <FormSelect
          value={default_value}
          onChange={onChange}
          css={{
            marginLeft: "16px",
          }}
        >
          {options.map(({ value, label }: any, index: any) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </FormSelect>
      )}
    </>
  );
};
