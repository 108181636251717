import { PartnerGroup } from "../../types/patner-group.types";

export const sortAndPreparePartnerGroups = (
  partnerGroups: PartnerGroup[] | null,
  selectedGroupIds: string[] | null,
): [PartnerGroup[] | null, string[] | null] => {
  if (!partnerGroups) return [null, null];

  const sortedGroups = [...partnerGroups].sort((a, b) => {
    if (a.isDefault !== b.isDefault) return a.isDefault ? -1 : 1;
    return a.name.localeCompare(b.name);
  });

  sortedGroups.unshift({
    groupId: "ungrouped",
    name: "Ungrouped",
    isDefault: false,
  });
  sortedGroups.unshift({
    groupId: "all",
    name: "All Groups",
    isDefault: false,
  });

  let defaultSelectedGroups: string[] = [];
  sortedGroups.forEach((group) => {
    if (
      (selectedGroupIds === null || selectedGroupIds.length === 0) &&
      group.isDefault
    ) {
      defaultSelectedGroups.push(group.groupId);
    } else if (
      selectedGroupIds &&
      selectedGroupIds.indexOf(group.groupId) >= 0
    ) {
      defaultSelectedGroups.push(group.groupId);
    }
  });

  return [sortedGroups, defaultSelectedGroups.sort()];
};
