import { PlusCircleIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";

import { Grid } from "../../components/Grid";
import { GroupTable, GroupTableRef } from "../../components/GroupTable";
import { Panel } from "../../components/Panel";

interface TabGroupsProps {
  partnerId: string;
}

/**
 * Panel to add Groups to a partner
 * @param props {TabGroupsProps} Component Props
 * @returns React.FC
 */
export const TabGroups: React.FC<TabGroupsProps> = ({ partnerId }) => {
  const groupTableRef = useRef<GroupTableRef>(null);

  const handleAddGroup = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    groupTableRef.current?.actionAddGroup();
  };

  return (
    <Grid>
      <Panel
        title="Groups"
        buttons={
          <a href="#" onClick={handleAddGroup}>
            <PlusCircleIcon strokeWidth={2} />
          </a>
        }
      >
        <GroupTable filter={{ partnerId }} ref={groupTableRef} />
      </Panel>
    </Grid>
  );
};
