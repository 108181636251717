import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

import { GQLResponse } from "../types/common.types";

import { Box } from "./Box";
import { NoData } from "./NoData";

import { styled } from "@/stitches";

interface TableProps<T> {
  columns: ColumnDef<T, any>[];
  data: GQLResponse<T>;
  onPaginationChange?: OnChangeFn<PaginationState>;
  pagination?: PaginationState;
}

const Table = <T extends unknown>({
  columns,
  data,
  onPaginationChange,
  pagination,
}: TableProps<T>) => {
  const defaultData = React.useMemo(() => [], []);

  const options = {
    data: data.rows ?? defaultData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  };

  if (pagination) {
    Object.assign(options, {
      pageCount: data.pageInfo.totalPages ?? -1,
      state: {
        pagination,
      },
      onPaginationChange,
      manualPagination: true,
    });
  }

  const table = useReactTable(options);
  const rows = table.getRowModel().rows;

  if (!rows.length) {
    return <NoData />;
  }

  return (
    <Box>
      <table cellPadding={0} cellSpacing={0}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {pagination && (
        <Box
          css={{
            alignItems: "center",
            color: "#8B90A0",
            display: "flex",
            fontWeight: 400,
            justifyContent: "center",
            padding: "20px 0",
            width: "100%",
          }}
        >
          <PaginationButton
            onClick={() => table.firstPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronDoubleLeftIcon />
          </PaginationButton>
          <PaginationButton
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <ChevronLeftIcon />
          </PaginationButton>
          <span>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </span>
          <PaginationButton
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronRightIcon />
          </PaginationButton>

          <PaginationButton
            onClick={() => table.lastPage()}
            disabled={!table.getCanNextPage()}
          >
            <ChevronDoubleRightIcon />
          </PaginationButton>
        </Box>
      )}
    </Box>
  );
};

const PaginationButton = styled("button", {
  cursor: "pointer",
  flex: "0 0 30px",
  margin: "0 10px",
  padding: "6px",

  "&:disabled": {
    cursor: "default",
    opacity: 0.5,
  },
});

export default Table;
