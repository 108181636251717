import { useQuery } from "@apollo/client";
import React, { useMemo, useRef, useState } from "react";

import { AgesChart } from "../components/AgesChart";
import { Box } from "../components/Box";
import CompaniesTable from "../components/CompaniesTable";
import { CompletionsChart } from "../components/CompletionsChart";
import { GenderChart } from "../components/GenderChart";
import { Grid } from "../components/Grid";
import { Panel } from "../components/Panel";
import { Search } from "../components/Search";
import { UserCount } from "../components/UserCount";
import { UserTable } from "../components/UserTable";
import { useTitle } from "../hooks/useTitle";
import { COUNT_TREATMENTS } from "../queries/treatment.queries";
import { COUNT_USERS } from "../queries/user.queries";
import { TreatmentCountsResponse } from "../types/treatment.types";
import { UserCountsResponse } from "../types/user.types";

export const Corporate = () => {
  useTitle("Corporate Enrollment");
  const userTableRef = useRef<any>();

  const { data: userData, loading: userLoading } = useQuery<{
    userCounts: UserCountsResponse[];
  }>(COUNT_USERS, {
    variables: {
      filter: {
        includeDirect: false,
      },
    },
  });

  const { data: treatmentData, loading: treatmentLoading } = useQuery<{
    treatmentCounts: TreatmentCountsResponse;
  }>(COUNT_TREATMENTS, {
    variables: {
      filter: {
        includeDirect: false,
      },
    },
  });

  const userCounts: any = useMemo(() => {
    const counts = {
      corp: 0,
      corpMonth: 0,
    };

    userData?.userCounts.forEach((response) => {
      if (response.partnerId !== "d2c") {
        counts.corp += response.count;
        counts.corpMonth += response.thisMonth;
      }
    });

    return counts;
  }, [userData]);

  const treatmentCount = useMemo(
    () => treatmentData?.treatmentCounts,
    [treatmentData],
  );

  const actionBulkEdit = () => {
    userTableRef.current.actionBulkEdit();
  };

  return (
    <>
      <Search />
      <Box as="h1">Corporate Enrollment</Box>
      <Grid>
        <UserCount
          count={userCounts.corp}
          label="Corp. Enroll"
          loading={userLoading}
          monthly={userCounts?.corpMonth}
        />
        <UserCount
          count={treatmentCount?.count}
          label="Total Treatments"
          loading={treatmentLoading}
          monthly={treatmentCount?.thisMonth}
        />
      </Grid>
      <Grid>
        <Panel title="Companies">
          <CompaniesTable />
        </Panel>
      </Grid>
      <Grid>
        <Panel title="Total Completions">
          <CompletionsChart filter={{ includeDirect: false }} />
        </Panel>
        <Panel title="Total Gender">
          <GenderChart filter={{ includeDirect: false }} />
        </Panel>
        <Panel title="Total Age">
          <AgesChart filter={{ includeDirect: false }} />
        </Panel>
      </Grid>
      <Grid>
        <Panel title="Individuals">
          <UserTable
            filter={{ includeDirect: false, includeAssociations: true }}
            partnerGroups={null}
            ref={userTableRef}
          />
        </Panel>
      </Grid>
    </>
  );
};
