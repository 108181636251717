import { styled } from "@/stitches";

export const Input = styled("input", {
  display: "block",
  lineHeight: 1.45,
  fontSize: "18px",
  fontStyle: "normal",
  padding: "2px",
  width: "50%",
  '&[type="checkbox"]': {
    cursor: "pointer",
    display: "inline",
    width: "auto",
    marginLeft: "10px",
  },
});
