import { useMutation, useQuery } from "@apollo/client";
import { DIRECT_TO_CONSUMER_ID } from "@soovu/shared/constants";
import { Filter } from "@soovu/shared/types";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { month_day_year } from "../lib/date_format";
import { defaultPageSize } from "../lib/page-size";
import { getPartnerPath } from "../lib/util";
import { selectGroups } from "../pages/CompanyDetails/select-groups";
import { UPDATE_USER_GROUP } from "../queries/partner.queries";
import { GET_USERS } from "../queries/user.queries";
import { GQLResponse } from "../types/common.types";
import { PartnerGroup } from "../types/patner-group.types";
import { User } from "../types/user.types";

import { Box } from "./Box";
import { Modal } from "./Modal";
import Table from "./Table";
import { idSelect } from "./UserTable/id-select";

interface EnrollmentsTableProps {
  filter?: Filter;
  partnerGroups: PartnerGroup[] | null;
}

/* eslint-disable react/display-name */
export const UserTable = forwardRef(
  ({ filter, partnerGroups }: EnrollmentsTableProps, ref: any) => {
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
      pageIndex: 0,
      pageSize: defaultPageSize,
    });
    const modalRef = useRef();
    const selectAllRef = useRef<HTMLInputElement>(null);
    const checkboxRefs = useRef<(HTMLInputElement | null)[]>([]);
    const columnHelper = createColumnHelper<User>();

    const [addSelectedIds, setAddSelectedIds] = useState<number[]>([]);
    const [toSelectedGroup, setToSelectedGroup] = useState<string>("");

    const handlePaginationChange = (newPagination: any) => {
      setPagination(newPagination);
      setAddSelectedIds([]); // Clear selected IDs
      if (selectAllRef.current) {
        selectAllRef.current.checked = false;
        selectAllRef.current.indeterminate = false;
      }
    };

    useEffect(() => {
      if (selectAllRef.current) {
        selectAllRef.current.indeterminate =
          addSelectedIds.length > 0 &&
          addSelectedIds.length < checkboxRefs.current.length;
      }
    }, [addSelectedIds]);

    const pagination = React.useMemo(
      () => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize],
    );

    const actionBulkEdit = () => {
      (modalRef?.current as any)?.open();
    };

    useImperativeHandle(ref, () => ({
      actionBulkEdit: actionBulkEdit,
    }));

    const [updatedUsersToGroup] = useMutation(UPDATE_USER_GROUP, {
      refetchQueries: [GET_USERS],
    });

    const bulkSave = (formData: FormData) => {
      // console.log("bulkSave", toSelectedGroup, addSelectedIds);

      updatedUsersToGroup({
        variables: {
          groupId: toSelectedGroup,
          partnerId: filter?.partnerId,
          ids: addSelectedIds,
        },
      });
      setAddSelectedIds([]); // Clear selected IDs
      bulkCancel();
      return true;
    };
    const bulkCancel = () => {
      (modalRef?.current as any)?.close();
    };

    const { data, loading } = useQuery<{ users: GQLResponse<User> }>(
      GET_USERS,
      {
        variables: {
          pagination: {
            page: pageIndex + 1,
            pageSize,
          },
          filter,
        },
      },
    );

    const allColumns = {
      checkbox: columnHelper.accessor("id", {
        ...idSelect(
          selectAllRef,
          checkboxRefs,
          addSelectedIds,
          setAddSelectedIds,
        ),
      }),
      name: columnHelper.accessor(
        (row) => {
          if (
            (row.nameFirst === "" || row.nameFirst === null) &&
            (row.nameLast === "" || row.nameLast === null)
          ) {
            return row.email;
          }
          return (row.nameFirst ?? "") + " " + (row.nameLast ?? "");
        },
        {
          cell: (info) => {
            const prefix =
              info.row.original.Partner?.partnerUrl === DIRECT_TO_CONSUMER_ID
                ? "dtc"
                : "corporate";
            return (
              <Link to={`/${prefix}/user/${info.row.original.id}`}>
                {" "}
                <Box
                  as={"span"}
                  css={{
                    color: "#EF4F25",
                    fontWeight: 350,
                    whiteSpace: "nowrap",
                  }}
                >
                  {info.getValue()}
                </Box>
              </Link>
            );
          },
          header: "Individual",
        },
      ),
      email: columnHelper.accessor("email", {
        header: () => "Email",
      }),
      partner: columnHelper.accessor("Partner.name", {
        cell: (info) => (
          <Link to={getPartnerPath(info.row.original.Partner?.partnerUrl)}>
            <Box css={{ color: "#EF4F25", fontWeight: 350 }}>
              {info.getValue()}
            </Box>
          </Link>
        ),
        header: () => "Company",
      }),
      created: columnHelper.accessor("createdAt", {
        cell: (info) => {
          const value = info.getValue();
          if (!value) {
            return "null";
          }

          return month_day_year(value);
        },
        header: "Date Enrolled",
      }),
      employeeType: columnHelper.accessor("employeeType", {
        cell: (info) => info.getValue(),
        header: "Type",
      }),
      employer: columnHelper.accessor("employer", {
        cell: (info) => info.getValue(),
        header: "Type",
      }),
      id: columnHelper.accessor("id", {
        cell: (info) => {
          const id = parseInt(info.getValue(), 10);
          return id ? id + 1000 : null;
        },
        header: "UID",
      }),
    };

    const corpColumns = [
      allColumns.checkbox,
      allColumns.name,
      allColumns.email,
      allColumns.partner,
      allColumns.employeeType,
      allColumns.employer,
      allColumns.id,
      allColumns.created,
    ];

    const d2cColumns = [
      allColumns.checkbox,
      allColumns.name,
      allColumns.email,
      allColumns.id,
      allColumns.created,
    ];

    if (loading || !data) {
      return <Box>...loading</Box>;
    }

    return (
      <>
        <Table
          columns={filter?.includeDirect === false ? corpColumns : d2cColumns}
          data={data.users}
          onPaginationChange={handlePaginationChange}
          pagination={pagination}
        />

        <Modal
          title="Bulk Edit"
          submitText="Save"
          onOk={bulkSave}
          onCancel={bulkCancel}
          ref={modalRef}
        >
          {selectGroups(partnerGroups, [toSelectedGroup], (e: any) => {
            setToSelectedGroup(e.target.value);
          })}
        </Modal>
      </>
    );
  },
);
