import { useMutation } from "@apollo/client";

import {
  ADD_PARTNER_GROUP,
  DELETE_PARTNER_GROUP,
  GET_PARTNER_DETAILS,
  GET_PARTNER_GROUPS,
} from "../queries/partner.queries";

/**
 * Service to handle Partner Group Mutations
 * @param {string} partnerId Partner Id
 * @param {"add" | "delete"} mutationType Mutation Type
 * @returns
 */
export const useServicePartnerGroupMutation = (
  partnerId: string,
  mutationType: "add" | "delete",
) => {
  const MUTATION =
    mutationType === "add" ? ADD_PARTNER_GROUP : DELETE_PARTNER_GROUP;
  const actionName = mutationType === "add" ? "adding" : "deleting";

  return useMutation(MUTATION, {
    refetchQueries: [
      GET_PARTNER_GROUPS,
      {
        query: GET_PARTNER_DETAILS,
        variables: { id: partnerId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      console.log(
        `Partner group ${actionName} completed and queries refetched`,
      );
    },
    onError: (error) => {
      console.error(`Error ${actionName} partner group:`, error);
    },
  });
};
