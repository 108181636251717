import { Row } from "@tanstack/react-table";
import React from "react";

import { User } from "../../types/user.types";
import { Input } from "../Forms/Input";

export const idSelect = (
  selectAllRef: React.RefObject<HTMLInputElement>,
  checkboxRefs: React.MutableRefObject<(HTMLInputElement | null)[]>,
  selectedIds: number[],
  setSelectedIds: React.Dispatch<React.SetStateAction<number[]>>,
) => {
  return {
    cell: ({ row }: { row: Row<User> }) => {
      const id = parseInt(row.original.id, 10);
      return (
        <Input
          type="checkbox"
          ref={(el) => (checkboxRefs.current[row.index] = el)}
          data-id={id}
          checked={selectedIds.includes(id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedIds((prev: number[]) => [...prev, id]);
            } else {
              setSelectedIds((prev: number[]) =>
                prev.filter((selectedId) => selectedId !== id),
              );
            }
          }}
        />
      );
    },
    header: () => (
      <Input
        type="checkbox"
        ref={selectAllRef}
        checked={
          selectedIds.length === checkboxRefs.current.length &&
          checkboxRefs.current.length > 0
        }
        onChange={(e) => {
          const allIds = checkboxRefs.current
            .map((ref: HTMLInputElement | null) =>
              parseInt(ref?.getAttribute("data-id") as string, 10),
            )
            .filter((id: number): id is number => id !== null);

          if (e.target.checked) {
            setSelectedIds(allIds);
          } else {
            setSelectedIds([]);
          }
        }}
      />
    ),
    id: "select",
  };
};
