export async function download_usage_report(
  event: React.MouseEvent<HTMLAnchorElement>,
  getAccessTokenSilently: Function,
  month_year: string,
  partner: string = "all",
  groupIds: string[] = ["all"],
) {
  event.stopPropagation();
  const token = await getAccessTokenSilently();
  const filteredGroupIds = groupIds.filter((id) => id !== "all");

  const filename = `${partner}_${month_year}${filteredGroupIds.length > 0 ? `_${filteredGroupIds.join("-")}` : ""}_usagereport.csv`;
  console.log(`Downloading report: ${filename}`);

  fetch(`${process.env.REACT_APP_SERVER_URI}/download/${filename}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      authorization: `Bearer ${token}`,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    });
}
