import { gql, useQuery } from "@apollo/client";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { Link } from "react-router-dom";

import { getCurrentMonth, getPartnerPath } from "../lib/util";
import { COUNT_USERS } from "../queries/user.queries";
import { Partner } from "../types/partner.types";
import { UserCountsResponse } from "../types/user.types";

import { Box } from "./Box";
import { Button } from "./Button";
import { Loading } from "./Loading";
import { NoData } from "./NoData";
import Table from "./Table";

const columnHelper = createColumnHelper<UserCountsResponse>();

const columns = [
  columnHelper.accessor("partnerName", {
    cell: (info) => (
      <Link to={getPartnerPath(info.row.original.partnerId)}>
        <Box css={{ color: "#EF4F25", fontWeight: 350 }}>{info.getValue()}</Box>
      </Link>
    ),
    header: "Organization",
  }),
  columnHelper.accessor("count", {
    header: "Total",
  }),
  columnHelper.accessor("thisMonth", {
    header: `Month (${getCurrentMonth()})`,
  }),
  columnHelper.accessor("partnerId", {
    cell: (info) => (
      <Link to={getPartnerPath(info.getValue())}>
        <Button color="orange" size="large" outlined css={{ width: "100%" }}>
          View
        </Button>
      </Link>
    ),
    header: "Actions",
  }),
];

type CompaniesTableProps = {
  includeDirect?: boolean;
};

const CompaniesTable = ({ includeDirect = false }: CompaniesTableProps) => {
  const { data, loading } = useQuery<{
    userCounts: UserCountsResponse[];
  }>(COUNT_USERS);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <NoData />;
  }

  return (
    <Table
      columns={columns}
      data={{
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
          currentPage: 1,
          totalPages: 1,
        },
        rows: data.userCounts as any,
        totalCount: data.userCounts.length,
      }}
    />
  );
};

export default CompaniesTable;
