import { TrashIcon } from "@heroicons/react/24/outline";
import { DIRECT_TO_CONSUMER_ID } from "@soovu/shared/constants";
import { Filter } from "@soovu/shared/types";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { defaultPageSize } from "../lib/page-size";
import { useServicePartnerGroup } from "../services/useServicePartnerGroup";
import { useServicePartnerGroupMutation } from "../services/useServicePartnerGroupMutation";
import { PartnerGroup } from "../types/patner-group.types";

import { Box } from "./Box";
import { Input } from "./Forms/Input";
import { Label } from "./Forms/Label";
import { useGroupForm } from "./Hooks/useGroupForm";
import { Modal } from "./Modal";
import Table from "./Table";

interface EnrollmentsTableProps {
  filter?: Filter;
}
// In GroupTable.tsx
export interface GroupTableRef {
  actionAddGroup: () => void;
}

/* eslint-disable react/display-name */
export const GroupTable = forwardRef<GroupTableRef, any>(
  ({ filter }: EnrollmentsTableProps, ref: any) => {
    const partnerId = filter.partnerId;

    const modalRef = useRef();
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
      pageIndex: 0,
      pageSize: defaultPageSize,
    });

    const pagination = React.useMemo(
      () => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize],
    );

    const { data, loading } = useServicePartnerGroup(
      pageIndex,
      pageSize,
      filter,
    );
    const [addPartnerGroup] = useServicePartnerGroupMutation(partnerId, "add");
    const [deletePartnerGroup] = useServicePartnerGroupMutation(
      partnerId,
      "delete",
    );

    const {
      isDefaultGroup,
      setIsDefaultGroup,
      groupId,
      setGroupId,
      groupName,
      setGroupName,
      groupIdError,
      groupNameError,
      handleSubmit,
      resetForm,
    } = useGroupForm(addPartnerGroup, filter);

    const actionAddGroup = () => {
      (modalRef?.current as any)?.open();
    };
    useImperativeHandle(ref, () => ({
      actionAddGroup: actionAddGroup,
    }));

    const addGroup = () => {
      if (handleSubmit()) {
        (modalRef?.current as any)?.close();
      }
    };

    const cancelGroup = () => {
      (modalRef?.current as any)?.close();
      resetForm();
    };

    if (loading || !data) {
      return <Box>...loading</Box>;
    }

    const columnHelper = createColumnHelper<PartnerGroup>();

    const allColumns = {
      name: columnHelper.accessor(
        (row) => {
          return row.name;
        },
        {
          cell: (info) => {
            const prefix =
              info.row.original.partnerId === DIRECT_TO_CONSUMER_ID
                ? "dtc"
                : "corporate";
            return (
              <Link
                to={`/${prefix}${prefix !== "dtc" ? "/" + info.row.original.partnerId : ""}?groupId=${info.row.original.groupId}`}
              >
                {" "}
                <Box
                  as={"span"}
                  css={{
                    color: "#EF4F25",
                    fontWeight: 350,
                    whiteSpace: "nowrap",
                  }}
                >
                  {info.getValue()}
                </Box>
              </Link>
            );
          },
          header: "Name",
        },
      ),
      groupId: columnHelper.accessor("groupId", {
        header: () => "Group Id",
      }),
      isDefault: columnHelper.accessor(
        (row) => {
          return row.isDefault;
        },
        {
          cell: (info) => (info.getValue() ? "Default" : ""),
          header: "Default",
        },
      ),
      delete: columnHelper.accessor(
        (row) => {
          return row.groupId;
        },
        {
          cell: (info) => {
            return (
              <a
                href="#"
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
                  deletePartnerGroup({
                    variables: {
                      groupId: info.row.original.groupId,
                      partnerId: info.row.original.partnerId,
                    },
                  })
                }
                style={{
                  display: "flex",
                  width: "30px",
                  marginLeft: "auto",
                }}
              >
                <TrashIcon strokeWidth={2} />
              </a>
            );
          },
          header: "Delete",
          size: 30,
        },
      ),
    };

    const columns = [
      allColumns.name,
      allColumns.groupId,
      allColumns.isDefault,
      allColumns.delete,
    ];

    return (
      <>
        <Table
          columns={columns}
          data={data.partnerGroups}
          onPaginationChange={setPagination}
          pagination={pagination}
        />
        <Modal
          title="Add Group"
          submitText="Add"
          onOk={addGroup}
          onCancel={cancelGroup}
          ref={modalRef}
        >
          <Label>
            Default
            <Input
              type="checkbox"
              name="isDefault"
              checked={isDefaultGroup}
              onChange={(e) => setIsDefaultGroup(e.target.checked)}
            />
          </Label>
          <Label>Group Slug</Label>
          <Input
            type="text"
            name="groupId"
            value={groupId}
            css={groupIdError ? { border: "solid 1px rgb(239, 79, 37)" } : {}}
            onChange={(e) =>
              setGroupId(
                e.target.value
                  .toLowerCase()
                  .replaceAll(" ", "_")
                  .replace(/[^\w\d]/gm, ""),
              )
            }
          />
          <Label>Group Title</Label>
          <Input
            type="text"
            name="groupTitle"
            value={groupName}
            css={groupNameError ? { border: "solid 1px rgb(239, 79, 37)" } : {}}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </Modal>
      </>
    );
  },
);
