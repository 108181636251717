import React, {
  FormEvent,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";

import { Box } from "./Box";
import { Button } from "./Button";

import { styled } from "@/stitches";

interface ModalProps {
  children: string | React.ReactNode;
  title: string | React.ReactNode;
  submitText: string;
  onOk: Function;
  onCancel: Function;
}

const Wrapper = styled(Box, {
  background: "white",
  border: "1px solid #E7E7E7",
  borderRadius: "8px",
  boxShadow:
    "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
  display: "flex",
  flex: "1 1 0",
  flexDirection: "column",
  position: "fixed",
  zIndex: 1001,
  width: "75vw",
  height: "75vh",
  top: "12.5vh",
  left: "12.5vw",
});

const ModalBackground = styled("div", {
  background: "rgba(0, 0, 0, 0.5)",
  bottom: 0,
  left: 0,
  position: "fixed",
  right: 0,
  top: 0,
  zIndex: 1000,
});

const TitleStyled = styled("h3", {
  borderBottom: "1px solid #E7E7E7",
  lineHeight: 1.45,
  padding: "20px 32px 18px",
  position: "relative",
});

const Content = styled(Box, {
  flex: 1,
  minHeight: 50,
  overflowX: "auto",
  padding: "20px 32px 18px",
  position: "relative",
});

const CTAS = styled(Box, {
  flex: 1,
  minHeight: 50,
  overflowX: "auto",
  padding: "20px 32px 18px",
  borderTop: "solid 1px #E7E7E7",
  position: "absolute",
  bottom: "0px",
  left: "0px",
  right: "0px",
  display: "flex",
  gap: "10px",
  flexDirection: "row",
});

// eslint-disable-next-line react/display-name
export const Modal = forwardRef(
  ({ children, title, submitText, onOk, onCancel }: ModalProps, ref: any) => {
    const [isActive, setIsActive] = useState(false);
    const [isPending, setIsPending] = useState(false);

    const open = () => {
      // console.log("Open Modal");
      setIsActive(true);
    };
    const close = () => {
      // console.log("Close Modal");
      setIsActive(false);
    };

    useImperativeHandle(ref, () => ({
      open: open,
      close: close,
    }));

    function action(event: FormEvent<HTMLFormElement>) {
      event.preventDefault();
      // console.log(event);
      setIsPending(true);
      const formData = new FormData(event.target as HTMLFormElement);
      onOk(formData);

      setIsPending(false);
      return;
    }

    function callCancel() {
      onCancel();
      return false;
    }

    function Submit({ submitText }: { submitText: string }) {
      return (
        <Button
          color="orange"
          size="large"
          type="submit"
          css={{ width: "50%" }}
          disabled={isPending}
        >
          {isPending ? "Submitting..." : submitText}
        </Button>
      );
    }

    return (
      isActive && (
        <>
          <Wrapper>
            <TitleStyled>{title}</TitleStyled>
            <Content>
              <form onSubmit={action}>
                {children}
                <CTAS>
                  <Button
                    size="large"
                    type="button"
                    css={{ width: "50%" }}
                    onClick={callCancel}
                  >
                    Cancel
                  </Button>
                  <Submit submitText={submitText}></Submit>
                </CTAS>
              </form>
            </Content>
          </Wrapper>
          <ModalBackground></ModalBackground>
        </>
      )
    );
  },
);
