import { useQuery } from "@apollo/client";
import { Filter } from "@soovu/shared/types";

import { GET_PARTNER_GROUPS } from "../queries/partner.queries";
import { GQLResponse } from "../types/common.types";
import { PartnerGroup } from "../types/patner-group.types";

/**
 * Service to handle Partner Group Query
 * @param {number} pageIndex Page Index
 * @param {number} pageSize Page Size
 * @param {Filter} filter Filter
 * @returns
 */
export const useServicePartnerGroup = (
  pageIndex: number,
  pageSize: number,
  filter: Filter,
) => {
  return useQuery<{
    partnerGroups: GQLResponse<PartnerGroup>;
  }>(GET_PARTNER_GROUPS, {
    variables: {
      pagination: {
        page: pageIndex + 1,
        pageSize,
      },
      filter,
    },
  });
};
