import React from "react";

import { month_day_year } from "../lib/date_format";
import { User } from "../types/user.types";

import { Loading } from "./Loading";
import { NoData } from "./NoData";

import { styled } from "@/stitches";

const TableStyled = styled("table", {
  color: "#505565",
});

const TitleCellStyled = styled("td", {
  fontSize: 16,
  fontWeight: 350,
});

const InfoCellStyled = styled("td", {
  fontSize: 16,
  textAlign: "left",
});

const getAge = (birthDate: string | undefined) =>
  birthDate
    ? Math.floor(
        (new Date().getTime() - new Date(birthDate).getTime()) / 3.15576e10,
      ).toFixed(0)
    : undefined;

interface UserDetailsTableProps {
  loading: boolean;
  user: User | undefined;
}

export const UserDetailsTable = ({ loading, user }: UserDetailsTableProps) => {
  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return <NoData />;
  }

  return (
    <TableStyled cellPadding={0} cellSpacing={0}>
      <tbody>
        <tr>
          <TitleCellStyled>Name</TitleCellStyled>
          <InfoCellStyled>
            {user.nameFirst} {user.nameLast}
          </InfoCellStyled>
        </tr>
        {user.dob && (
          <>
            <tr>
              <TitleCellStyled>Date of Birth</TitleCellStyled>
              <InfoCellStyled>
                {new Date(user.dob).toLocaleDateString("en-US", {
                  timeZone: "UTC",
                })}
              </InfoCellStyled>
            </tr>
            <tr>
              <TitleCellStyled>Age</TitleCellStyled>
              <InfoCellStyled>{getAge(user.dob)}</InfoCellStyled>
            </tr>
          </>
        )}
        <tr>
          <TitleCellStyled>Company</TitleCellStyled>
          <InfoCellStyled>{user.Partner.name}</InfoCellStyled>
        </tr>
        {user.partnerGroup && (
          <tr>
            <TitleCellStyled>Partner Group</TitleCellStyled>
            <InfoCellStyled>{user.partnerGroup}</InfoCellStyled>
          </tr>
        )}
        {user.gender && (
          <tr>
            <TitleCellStyled>Gender</TitleCellStyled>
            <InfoCellStyled>{user.gender}</InfoCellStyled>
          </tr>
        )}
        {user.employer && (
          <tr>
            <TitleCellStyled>Employer</TitleCellStyled>
            <InfoCellStyled>{user.employer}</InfoCellStyled>
          </tr>
        )}
        {user.addressMailing && (
          <tr>
            <TitleCellStyled>Mailing Address</TitleCellStyled>
            <InfoCellStyled>{user.addressMailing}</InfoCellStyled>
          </tr>
        )}
        <tr>
          <TitleCellStyled>Email</TitleCellStyled>
          <InfoCellStyled>{user.email}</InfoCellStyled>
        </tr>
        {user.altEmail && (
          <tr>
            <TitleCellStyled>Alternative Email</TitleCellStyled>
            <InfoCellStyled>{user.altEmail}</InfoCellStyled>
          </tr>
        )}
        {user.phoneHome && (
          <tr>
            <TitleCellStyled>Home Phone</TitleCellStyled>
            <InfoCellStyled>{user.phoneHome}</InfoCellStyled>
          </tr>
        )}
        {user.phoneMobile && (
          <tr>
            <TitleCellStyled>Mobile Phone</TitleCellStyled>
            <InfoCellStyled>{user.phoneMobile}</InfoCellStyled>
          </tr>
        )}
        {user.altPhoneMobile && (
          <tr>
            <TitleCellStyled>Alternative Phone Number</TitleCellStyled>
            <InfoCellStyled>{user.altPhoneMobile}</InfoCellStyled>
          </tr>
        )}
        {user.employeeType && (
          <tr>
            <TitleCellStyled>Enrollment Type</TitleCellStyled>
            <InfoCellStyled>{user.employeeType}</InfoCellStyled>
          </tr>
        )}
        <tr>
          <TitleCellStyled>Marketing Enabled</TitleCellStyled>
          <InfoCellStyled>
            {user.marketingEnabled
              ? `Yes - ${new Date(user.marketingEnabledOn).toLocaleString(
                  "en-US",
                  {
                    timeZone: "UTC",
                  },
                )} via ${user.marketingEnabledSource}`
              : "No"}
          </InfoCellStyled>
        </tr>
        <tr>
          <TitleCellStyled>SMS Permission</TitleCellStyled>
          <InfoCellStyled>
            {user.smsEnabled
              ? `Yes - ${new Date(user.smsEnabledOn).toLocaleString("en-US", {
                  timeZone: "UTC",
                })} via ${user.smsEnabledSource}`
              : "No"}
          </InfoCellStyled>
        </tr>
        <tr>
          <TitleCellStyled>Enrollment Date</TitleCellStyled>
          <InfoCellStyled>{month_day_year(user.createdAt)}</InfoCellStyled>
        </tr>
        <tr>
          <TitleCellStyled>First Usage Date</TitleCellStyled>
          <InfoCellStyled>
            {user.firstTimeUse
              ? new Date(user.firstTimeUse).toLocaleDateString()
              : ""}
          </InfoCellStyled>
        </tr>
        <tr>
          <TitleCellStyled>UID</TitleCellStyled>
          <InfoCellStyled>
            {user.altId ? `${parseInt(user.altId) + 1000} / ` : ""}
            {parseInt(user.id) + 1000}
          </InfoCellStyled>
        </tr>
      </tbody>
    </TableStyled>
  );
};
