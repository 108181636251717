import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";

import { AgesChart } from "../../components/AgesChart";
import { Divider } from "../../components/Divider";
import { EnrollmentChart } from "../../components/EnrollmentChart";
import { GenderChart } from "../../components/GenderChart";
import { Grid } from "../../components/Grid";
import { PainTypeChart } from "../../components/PainTypeChart";
import { Panel } from "../../components/Panel";
import { UserTable } from "../../components/UserTable";
import { PartnerGroup } from "../../types/patner-group.types";

export const TabEnrollment = ({
  phi,
  partnerId,
  groupIds,
  partnerGroups,
}: {
  phi: boolean;
  partnerId: string | undefined;
  groupIds: string[] | null;
  partnerGroups: PartnerGroup[] | null;
}) => {
  const userTableRef = useRef<any>();
  const actionBulkEdit = () => {
    userTableRef.current.actionBulkEdit();
  };
  return (
    <>
      <Grid>
        <Panel title="Monthly Enrollments">
          <EnrollmentChart filter={{ partnerId, groupIds }} />
        </Panel>
        <Panel title="Pain Types">
          <PainTypeChart filter={{ partnerId, groupIds }} />
        </Panel>
      </Grid>
      <Grid>
        <Panel title="Gender">
          <GenderChart filter={{ partnerId, groupIds }} />
        </Panel>
        <Panel title="Age">
          <AgesChart filter={{ partnerId, groupIds }} />
        </Panel>
      </Grid>
      <Divider />
      {phi && (
        <Grid>
          <Panel
            title="Registrations"
            buttons={
              <a href="#" onClick={actionBulkEdit}>
                <PencilSquareIcon strokeWidth={2} />
              </a>
            }
          >
            <UserTable
              filter={{ partnerId, groupIds }}
              partnerGroups={partnerGroups}
              ref={userTableRef}
            />
          </Panel>
        </Grid>
      )}
    </>
  );
};
