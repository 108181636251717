import { useQuery } from "@apollo/client";
import { DIRECT_TO_CONSUMER_ID } from "@soovu/shared/constants";
import { Filter } from "@soovu/shared/types";
import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { defaultPageSize } from "../lib/page-size";
import { GET_ACTIVE_USAGE } from "../queries/usage.queries";
import { GQLResponse } from "../types/common.types";
import { ActiveUsage } from "../types/usage.types";

import { Box } from "./Box";
import Table from "./Table";

const columnHelper = createColumnHelper<ActiveUsage>();

const columnDictionary = {
  startDate: columnHelper.accessor("startDate", {
    cell: (cell) => {
      const value = new Date(cell.getValue());
      return <>{value.toLocaleDateString()}</>;
    },
    header: "Date",
  }),
  name: columnHelper.accessor(
    (row) => (row.nameFirst ?? "") + " " + (row.nameLast ?? ""),
    {
      cell: (info) => {
        const prefix =
          info.row.original.partnerUrl === DIRECT_TO_CONSUMER_ID
            ? "dtc"
            : "corporate";
        return (
          <Link to={`/${prefix}/user/${info.row.original.userId}#usage`}>
            {" "}
            <Box
              as={"span"}
              css={{ color: "#EF4F25", fontWeight: 350, whiteSpace: "nowrap" }}
            >
              {info.getValue()}
            </Box>
          </Link>
        );
      },
      header: "Individual",
    },
  ),
  email: columnHelper.accessor("email", {
    header: () => "Email",
  }),
  sessions: columnHelper.accessor("totalSessions", {
    header: "# Sessions",
  }),
};

const columns = [
  columnDictionary.startDate,
  columnDictionary.name,
  columnDictionary.email,
  columnDictionary.sessions,
];

interface ActiveUsageTableProps {
  filter?: Filter;
}

export const ActiveUsageTable = ({ filter }: ActiveUsageTableProps) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, loading } = useQuery<{ activeUsage: GQLResponse<ActiveUsage> }>(
    GET_ACTIVE_USAGE,
    {
      variables: {
        pagination: {
          page: pageIndex + 1,
          pageSize,
        },
        filter,
      },
    },
  );

  if (loading || !data) {
    return <Box>...loading</Box>;
  }

  return (
    <Table
      columns={columns}
      data={data.activeUsage}
      onPaginationChange={setPagination}
      pagination={pagination}
    />
  );
};
