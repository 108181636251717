import { gql } from "@apollo/client";

export const GET_PARTNER_DETAILS = gql`
  query GetPartnerDetails($id: String!) {
    partnerDetails(id: $id) {
      name
      PartnerGroups {
        groupId
        name
        isDefault
      }
    }
  }
`;

/**
 * Query to fetch all partners
 * @param $pagination Pagination object
 * @param $filter Filter object
 * @returns Partners data
 *
 * Was used to fill homepage, has been removed and is not longer in use
 */
const GET_PARTNERS = gql`
  query GetAllPartners($pagination: Pagination, $filter: Filter) {
    partners(pagination: $pagination, filter: $filter) {
      pageInfo {
        currentPage
        totalPages
      }
      totalCount
      rows {
        name
        partnerUrl
        Enrollments {
          rowModifiedDatetime
        }
        Users {
          createdAt
        }
      }
    }
  }
`;

export const GET_PARTNER_GROUP = gql`
  query GetPartnerGroupQuery($id: ID!) {
    partnerGroup(groupId: $id, partnerId: $partnerId) {
      groupId
      name
      partnerId
      isDefault
    }
  }
`;

export const GET_PARTNER_GROUPS = gql`
  query GetPartnerGroupsQuery($pagination: Pagination, $filter: Filter) {
    partnerGroups(pagination: $pagination, filter: $filter) {
      pageInfo {
        currentPage
        totalPages
      }
      rows {
        groupId
        name
        partnerId
        isDefault
      }
    }
  }
`;

export const ADD_PARTNER_GROUP = gql`
  mutation AddPartnerGroupQuery(
    $groupId: String!
    $name: String!
    $partnerId: String!
    $isDefault: Boolean
  ) {
    addPartnerGroup(
      groupId: $groupId
      name: $name
      partnerId: $partnerId
      isDefault: $isDefault
    ) {
      groupId
      name
      partnerId
      isDefault
    }
  }
`;

export const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup(
    $groupId: String!
    $partnerId: String!
    $ids: [Int!]!
  ) {
    updateUserGroup(groupId: $groupId, partnerId: $partnerId, ids: $ids) {
      updated
    }
  }
`;

export const DELETE_PARTNER_GROUP = gql`
  mutation DeletePartnerGroup($groupId: String!, $partnerId: String!) {
    deletePartnerGroup(groupId: $groupId, partnerId: $partnerId) {
      deleted
    }
  }
`;
