import React from "react";

import { UserCount } from "../../components/UserCount";
import { TreatmentCountsResponse } from "../../types/treatment.types";
import { UserCountsResponse } from "../../types/user.types";

export const chartTotalCounts = (
  userCount: UserCountsResponse | undefined,
  userLoading: boolean | undefined,
  treatmentCount: TreatmentCountsResponse | undefined,
  treatmentLoading: boolean | undefined,
) => {
  return (
    <>
      <UserCount
        count={userCount?.count}
        label="Corp. Enroll"
        loading={userLoading}
        monthly={userCount?.thisMonth}
      />
      <UserCount
        count={treatmentCount?.count}
        label="Total Treatments"
        loading={treatmentLoading}
        monthly={treatmentCount?.thisMonth}
      />
    </>
  );
};
